import React from "react";
import "../styles/Calendaire.css";

export default function Calendaire() {
     return (
          <div style="{{" padding:="" "0%="" 1%",="" }}="">
               <p style="{{" textTransform:="" "uppercase",="" fontSize:="" "17px",="" fontWeight:="" "500",="" letterSpacing:="" "11px",="" margin:="" "o%",="" }}="">
                    Próximamente
               </p>

               <ul style="{{" margin:="" "0%",="" listStyle:="" "none",="" padding:="" }}="">
                    <li>
                         2025 - Nuevo proyecto para The Interstring Project (DE).
                         Nueva pieza para 4 guitarras eléctricas.
                    </li>
                    <li>
                         12/04/2025 - Infused 3'600'000ms, colabora con
                         Goran Nježić en un nuevo proyecto multimedia, encargado
                         de la Bienal de Música de Zagreb, estrenado por el conjunto Elision
                         ensemble como parte del MZB '25 (HR).
                    </li>
                    <li>
                         11/03/2025 LIFE electronics, Émilie Girard-Charest, le
                         Phoenix à Valenciennes (FR)
                    </li>
                    <li>
                         13/01/2025 - Infused 3'600'000ms (versión reducida), nuevo
                         proyecto multimedia, como parte del programa de predoctorado ICST en la
                         ZHdK (CH), estrenado por Joshua Hyde + Francisco Uberto.
                    </li>
                    {/* <li>
                         24/10/2024 Todas mis neuronas tienen clítoris, Flock Lab,
                         Ambassade de l'Argentine à Paris, París (FR)
                    </li>
                    <li>
                         17/10/2024 Todas mis neuronas tienen clítoris, Flock Lab, CRD de
                         Pantin, Pantin (FR)
                    </li> */}

                    {/* <li>
                         28/06/24 1Up - solo set, Gallerie du Haut Pavé, París
                         (FR)
                    </li> */}
                    {/* <li>
                         15/05/24 Dúo K.A.M.I.K.A.Z.E. B-L en el Kater Blau de
                         Berlín en el marco de classicalNEXT '24(DE)
                    </li>
                    <li>
                         27/04/24 Dúo K.A.M.I.K.A.Z.E. B-L en The music parlour
                         (ayuntamiento) Singapur (SG)
                    </li> */}
                    {/* <li>
                         15/03/24 Paradise (or how I'll get there from a door  en
                         la tercera planta), salle d'orchestre à la HEAR,
                         Estrasburgo (FR)
                    </li>
                    <li>
                         02/02/24 - LIFE electronics. masterización en el estudio "la
                         cave à son" (+ info próximamente), París (FR)
                    </li>
                    <li>
                         11/01/24 - All by myself - solo set performance -
                         Maison de l'Argentine - París (FR)
                    </li>

                    <li>
                         25/11/23 - K.A.M.I.K.A.Z.E - dúo B-L - hcfm// festival
                         (REINO UNIDO)
                    </li>
                    <li>
                         11/11/23 - P.A.R.A.D.I.S.E. - Li Li-Chin - C-Lab -
                         Taipei (TW)
                    </li>
                    <li>
                         26/10/23 - Elettrificare il Rosa - Trío Métallique -
                         Cité International des Arts, París (FR)
                    </li> */}
               </ul>
          </div>
     );
}
