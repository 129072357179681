import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./components/Home";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
     {
          path: "/",
          element: <app></app>,
          children: [
               {
                    path: "/",
                    element: <home></home>,
               },
          ],
     },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
     <react.strictmode>
          {/* <app></app> */}
          <routerprovider router="{router}"></routerprovider>
     </react.strictmode>
);

// Si quieres empezar a medir el rendimiento en tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o enviar a un punto final de análisis. Más información: https://bit.ly/CRA-vitals
reportWebVitals();
